import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WeCare from "../images/about-summary-we-care.png"
import WeLearn from "../images/about-summary-we-learn.svg"
import WeHelp from "../images/about-summary-we-help.svg"
import { Container, Row, Col } from "react-bootstrap"
// import Logo from "../images/mainLogo.png"

const AboutSummary2 = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
      />
      <div className="about-summary-wrapper">
        <div className="about-summary-heading">
          <div className="overlay" />
          <div className="container">
            <h1 className="text-blue text-center mb-5 relative">
              About PeerSource
            </h1>
            <h3 className="text-blue relative">
              Specialized staffing and recruiting. Results that{" "}
              <i>make a difference.</i>
            </h3>
            <Link className="common-btn block relative" to="/contact-us">
              Contact Us
            </Link>
            {/* <img src={Logo} alt="PeerSource" className="img-fluid relative" /> */}
          </div>
        </div>
        <div className="about-summary-content">
          <Container style={{ paddingBottom: "100px" }}>
            <h2 className="text-center benefits-flair mb-5 text-white">
              A History of Excellence
            </h2>
            <Row className="justify-content-center">
              <Col lg={9}>
                {/* <h4 className="mb-5">
                Without our talented candidates like you, there would be no
                PeerSource! When you choose PeerSource to help you find the
                perfect position, we are committed to offer the best recruiting
                experience you have ever received.
              </h4> */}
                <p className="mb-5 text-white">
                  Born and bred right here in Colorado, PeerSource was founded
                  in 2011 and now serves clients and candidates from Florida to
                  California as well as in many other hot spots across the
                  nation.
                </p>
                <p className="mb-5 text-white">
                  Although PeerSource started off in the IT sector, in 2023 we
                  opened up a new division - PeerSource Oncology - that staffs
                  locum radiation oncology professionals at healthcare
                  facilities nationwide.
                </p>

                <p className="mb-5 text-white">
                  With a laser-sharp focus in high end recruitment & staffing,
                  we are here to help you succeed – whether you need to hire the
                  best talent on your team or you’re ready to make a career
                  move.
                </p>

                <Link className="common-btn block relative" to="/contact-us">
                  Let’s Get Started
                </Link>
              </Col>
            </Row>
          </Container>
          <div className="container">
            <h2 className="text-center benefits-flair mb-5 text-white">
              What Sets PeerSource Apart
            </h2>
            <div className="row align-items-center justify-content-center mb-5">
              <div className="col-md-3 col-lg-2 text-center mb-2 mb-md-0">
                <img src={WeCare} alt="" width="150px" className="img-fluid" />
                <span className="h3 text-uppercase text-white font-weight-bold d-block mt-3">
                  We Care
                </span>
              </div>
              <div className="col-md-9">
                <div className="bg-white p-4 p-md-5">
                  <p>
                    WE CARE to understand people and their job requirements.
                  </p>
                  <p>
                    Unlike other recruiters, our priority is you, not us. Our
                    core promise is to understand both hiring leader{" "}
                    <strong>AND</strong> candidate requirements, and get the
                    details right for both so that we can place the{" "}
                    <strong>RIGHT</strong> person, the <strong>FIRST</strong>{" "}
                    time.
                  </p>
                  <p>
                    We interview every candidate <strong>TWICE</strong> to
                    ensure they are qualified prior to submitting their resume
                    to you. You'll receive a detailed Recruiter Summary which
                    highlights why we believe the candidate is the right fit for{" "}
                    <strong>YOU</strong>.
                  </p>
                  <p>
                    We will <strong>NEVER</strong> waste your time
                    transactionally shuffling keyword-based resumes to you with
                    no due diligence performed.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mb-5">
              <div className="col-md-3 col-lg-2 text-center mb-2 mb-md-0">
                <img src={WeLearn} alt="" width="150px" className="img-fluid" />
                <span className="h3 text-uppercase text-white font-weight-bold d-block mt-3">
                  We Learn
                </span>
              </div>
              <div className="col-md-9">
                <div className="bg-white p-4 p-md-5">
                  <p>
                    WE LEARN and as we repeatedly engage, we get{" "}
                    <strong>BETTER</strong>.
                  </p>
                  <p>
                    Once you are our partner, we make learning about your
                    preferences a top priority to get solid results. We want to
                    know your:
                  </p>
                  <div className="row">
                    <div className="col-md-5">
                      <ul className="m-0">
                        <li>Company culture</li>
                        <li>Team dynamics</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="m-0">
                        <li>Gaps in skills</li>
                        <li>Company initiatives & goals</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-lg-2 text-center mb-2 mb-md-0">
                <img src={WeHelp} alt="" width="150px" className="img-fluid" />
                <span className="h3 text-uppercase text-white font-weight-bold d-block mt-3">
                  We Help
                  <br />
                  You Grow
                </span>
              </div>
              <div className="col-md-9">
                <div className="bg-white p-4 p-md-5">
                  <p>
                    WE HELP YOU GROW so that you spend less time recruiting, and
                    more time building.
                  </p>
                  <p>
                    We believe that by minimizing the time you have to spend
                    recruiting, interviewing, and onboarding, we can help
                    mitigate that opportunity cost. Lost time = lost dollars.
                  </p>
                  <p>
                    This is what distinguishes us from our competitors and
                    positions us to confidently offer our{" "}
                    <strong>
                      1-year guarantee on FTE placements with Lifetime Placement
                      Assurance
                    </strong>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Container
            fluid
            className="text-center"
            style={{ padding: "100px 0" }}
          >
            <Container>
              <h2 className="text-white">Your Search Starts Here</h2>
              <h3
                className="text-center btech-flair mb-5 text-white text-center"
                style={{ maWidth: "33em", margin: "0 auto" }}
              >
                We are standing by to help.
              </h3>
              <div className="row">
                <div className="col-md-6">
                  <Link
                    className="white-btn border-none"
                    to="/technology"
                    style={{ fontSize: "20px" }}
                  >
                    Information Technology
                  </Link>
                </div>{" "}
                <div className="col-md-6">
                  <Link
                    className="white-btn border-none"
                    to="/oncology"
                    style={{ fontSize: "20px" }}
                  >
                    Radiation Oncology
                  </Link>
                </div>
              </div>
            </Container>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default AboutSummary2

export const aboutSummaryPage = graphql`
  query aboutSummaryPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
